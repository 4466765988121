import React from 'react'
import { Link, graphql } from 'gatsby'

import PageLayout from '../layout/page'
import MetaSEO from '../components/MetaSEO'
import Endorsement from '../layout/EndorsementsPageSpanish'

class EndorsementPage extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {}

  formLoaded() {}

  render() {
    return (
      <PageLayout showBigCircle={false}>
        <MetaSEO
          title="Endorsements - Cabán for Council"
          keywords={[
            `tiffany cabán`,
            `tiffany caban`,
            `caban for queens`,
            `caban`,
            `cabán`,
            `queens district attorney`,
            `district attorney`,
          ]}
          url="https://www.cabanforqueens.com/endorsements"
        />
        <Endorsement {...this.props.data.endorsements.frontmatter} />
      </PageLayout>
    )
  }
}

// <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
//   <Image />
// </div>
export default EndorsementPage

export const EndorsementPageQuery = graphql`
  query endorsementPageQuery2 {
    endorsements: markdownRemark(
      frontmatter: { uniq: { eq: "endorsements" } }
    ) {
      frontmatter {
        individualEndorsements {
          name
          link
          image
          position
          is_featured
          body
        }
        orgList {
          link
          image
          name
        }
      }
    }
  }
`
