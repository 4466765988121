import React, { useState } from 'react'
import Script from 'react-load-script'
import { Form } from 'react-bootstrap'

const VolunteerForm = (props) => {
  if (props.flat) {
    return (
      <VolunteerFormDefault className={`vol-form-flat ${props.className}`}>
        {props.children}
      </VolunteerFormDefault>
    )
  }

  return (
    <VolunteerFormDefault className={`${props.className}`}>
      {props.children}
    </VolunteerFormDefault>
  )
}

export const VolunteerFormDefault = (props) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [zip, setZip] = useState('')

  const spaceIndex = name.indexOf(' ') > -1 ? name.indexOf(' ') : null
  return (
    <section className={`vol-form ${props.className}`}>
      <div id="mc_embed_signup">
        <Form
          action="https://cabanforqueens.us17.list-manage.com/subscribe/post?u=cccf5e333a279b39944cd3a72&amp;id=f72f75eb16"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
          novalidate
        >
          <div id="mc_embed_signup_scroll">
            <div className="mb-2 d-flex flex-column flex-lg-row">
              <input
                type="text"
                value={name}
                placeholder="Nombre"
                name="FULLNAME"
                size="30"
                onChange={(e) => setName(e.target.value)}
                className="py-3  mb-2 mb-lg-0  px-4 border-0 font-0 mr-2 rounded"
                id="mce-FULLNAME"
                required
              />
              <input
                type="email"
                placeholder="Email"
                value={email}
                name="EMAIL"
                size="30"
                onChange={(e) => setEmail(e.target.value)}
                className="py-3 px-4 border-0  rounded font-0 mr-2"
                id="mce-EMAIL"
                required
              />
            </div>
            <div className="mc-field-group d-flex flex-column flex-lg-row">
              <input
                type="text"
                placeholder="Teléfono"
                name="Phone"
                size="21"
                className="py-3 mb-2 mb-lg-0 px-4 rounded font-0 mr-2 border-0 "
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                id="mce-PHONE"
                required
              />
              <input
                type="number"
                placeholder="Código Postal"
                value={zip}
                name="ZIPCODE"
                size="5"
                className="py-3 mb-2 mb-lg-0 px-4 rounded font-0 mr-2 border-0"
                onChange={(e) => setZip(e.target.value)}
                id="mce-ZIPCODE"
                required
                style={{ width: '120px' }}
              />

              <input
                type="submit"
                value="ÚNETE"
                name="subscribe"
                id="mc-embedded-subscribe"
                className="font-0 border-0 bg-pop1 color-white font-weight-bold text-uppercase rounded  p-3"
              />
            </div>

            <div
              style={{ position: 'absolute', left: '-5000px' }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_cccf5e333a279b39944cd3a72_f72f75eb16"
                tabindex="-1"
                value=""
              />
            </div>

            <input
              type="hidden"
              value={spaceIndex ? name.substring(0, spaceIndex) : name}
              name="FNAME"
              className="fname"
              id="mce-FNAME"
            />
            <input
              type="hidden"
              value={spaceIndex ? name.substring(spaceIndex) : ''}
              name="LNAME"
              className="lname"
              id="mce-LNAME"
            />
          </div>
        </Form>
      </div>
    </section>
  )
}

export default VolunteerForm
