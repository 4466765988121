import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import FullPageSection from '../components/FullPageSection'
import HeroTitle from '../components/HeroTitle'
import Footer from '../components/FooterSpanish'
import Header from '../components/HeaderSpanish'

import bgImage from '../img/TC3.jpeg'
import tlcImage from '../img/about.png'

const EndorsementsPage = (props) => (
  <main>
    <Header className="bg-white" />
    <FullPageSection
      half
      className="overlay-blue align-items-center d-flex splash"
      bgImage={bgImage}
    >
      <Container>
        <Row>
          <Col xs={12}>
            <HeroTitle
              className="mt-5 pt-5"
              textArray={[
                'Respaldamos',
                'Tiffany Cabán Demócrata para',
                'el Consejo Municipal, Distrito 22',
                'Vota • Martes 22 de junio',
              ]}
            />
          </Col>
        </Row>
      </Container>
    </FullPageSection>

    <h1 className="text-center p-5" style={{ fontWeight: 'bold' }}>
      Avalado por
    </h1>
    <div className="mt-lg-3 pt-lg-5 pb-5 pt-xs-0 mt-0 pt-0">
      <Container>
        <Row className="d-flex flex-wrap align-items-center justify-content-center">
          {props.individualEndorsements.map((person) => (
            <Col
              md={4}
              xs={12}
              className="text-center pb-4 mb-3 col-md-4 col-12 d-flex flex-column justify-center align-items-center"
            >
              <div
                className={'rounded-circle d-block mb-3'}
                style={{
                  width: 200,
                  height: 200,
                  backgroundSize: 'cover',
                  backgroundImage: `url(/images${person.image})`,
                }}
              ></div>
              <p className="mb-0" style={{ fontWeight: 'bold' }}>
                {person.name}
              </p>
              <p className="mt-0">{person.position}</p>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
    <div className="mt-3 pt-0 pb-5 pt-lg-5">
      <Container className="mt-3">
        <Row>
          {props.orgList.map((org) => (
            <Col md={3} xs={6} className="text-center">
              <span
                className={'d-inline-block d-lg-block mb-lg-5 mb-2'}
                style={{
                  width: '100%',
                  paddingBottom: '100%',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
                  backgroundImage: `url(/images${org.image})`,
                }}
              ></span>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
    <Footer className="pt-5" />
  </main>
)

export default EndorsementsPage
