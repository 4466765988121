import React from 'react'
import Icon from 'react-fontawesome'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'gatsby'

import VolunteerForm from '../components/VolunteerFormSpanish'
import PaidForBy from '../components/PaidForByCabanForCouncil'

const Footer = (props) => (
  <footer className={props.className}>
    {!props.mini && (
      <section className="bg-base1 py-4">
        <Container>
          <Row>
            <Col md={2}>
              <nav>
                <ul className="list-unstyled">
                  <li className="mb-3">
                    <Link
                      to="/es/about"
                      className="color-white font-1 font-weight-bold "
                    >
                      Tiffany
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link
                      to="/es/issues"
                      className="color-white font-1 font-weight-bold "
                    >
                      Prioridades
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link
                      to="/es/endorsements"
                      className="color-white font-1 font-weight-bold "
                    >
                      Apoyadores
                    </Link>
                  </li>
                  {/* <li className='mb-3'><Link to="/toolkit"  className='color-white font-1 font-weight-bold '>Media Toolkit</Link></li> */}
                  <li className="mt-3 mt-lg-0">
                    <a
                      href="https://www.facebook.com/cabanforqueens"
                      className="d-inline-block mr-3 color-base2"
                      target="_blank"
                    >
                      <Icon size="2x" name="facebook" />
                    </a>
                    <a
                      href="https://instagram.com/cabanforqueens/"
                      className="d-inline-block mr-3 color-base2"
                      target="_blank"
                    >
                      <Icon size="2x" name="instagram" />
                    </a>
                    <a
                      href="https://twitter.com/cabanforqueens"
                      className="d-inline-block mr-3 color-base2"
                      target="_blank"
                    >
                      <Icon size="2x" name="twitter" />
                    </a>
                  </li>
                </ul>
              </nav>
            </Col>
            <Col md={{ span: 6, offset: 4 }}>
              <h2 className="">Voluntarios</h2>
              <VolunteerForm />
            </Col>
          </Row>
        </Container>
      </section>
    )}
    <section className="bg-base2 py-3">
      <Container>
        <Row>
          <Col
            span={12}
            className="col-12 d-flex flex-lg-row flex-column justify-content-between"
          >
            <div className="d-flex flex-lg-row flex-column  align-items-center">
              <PaidForBy />
            </div>
            <div className="d-flex flex-lg-row flex-column  align-items-center">
              <div className="mr-0 mr-lg-4">
                <a
                  href="mailto:info@cabanforqueens.com"
                  className="color-base1 font-lg-1 font-0 font-weight-bold d-flex align-items-center"
                >
                  <Icon name="envelope" size="2x" className="mr-2" />{' '}
                  info@cabanforqueens.com
                </a>
              </div>
              <div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  </footer>
)

export default Footer
